.main-container {
    text-align: center;
    width: 100%;
}

.title-container {
    padding: 20px;
    justify-content: center;
    align-items: center;
}

.name-title {
    font-size: 8em;
    margin-bottom: 0;
}

.project-row {
    padding-left: 10%; 
    padding-right: 10%;
}

.button-row {
    padding-bottom: 2%;
}

.button {
    margin: 5px;
    padding: 5px 20px;
}

@media (max-width: 768px) {
    .project-row {
        padding-left: 0px; 
        padding-right: 0px;
    }
}

@media (min-width: 2000px) {
    .project-row {
        padding-left: 20%; 
        padding-right: 20%;
    }
}
/* Responsive styling for title */
@media (max-width: 600px) {
    .name-title {
        font-size: 3em;
        margin-bottom: 15px;
    }
}

