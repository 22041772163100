.name-title{
    width: 100%;
    height: 17vh;
  
}

@media (max-width: 1200px) {
    .name-title {
        transform: scale(0.85);
        width: 120%;
    }
}

@media (max-width: 800px) {
    .name-title {
        transform: scale(0.75);
        width: 120%;
        height: 15vh;
    }
}

@media (max-width: 700px) {
    .name-title {
        transform: scale(0.75);
        width: 140%;
        height: 12vh;
    }
}

@media (max-width: 650px) {
    .name-title {
        transform: scale(0.75);
    }
}

@media (max-width: 550px) {
    .name-title {
        transform: scale(0.75);
        width: 140%;
    }
}

@media (max-width: 450px) {
    .name-title {
        transform: scale(0.75);
        width: 140%;
        height: 10vh;
    }
}


