.svg-image {
    width: 450px;
    height: 350px;
    
}

@media (max-width: 700px) {
    .svg-image {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 460px) {
    .svg-image {
        width: 250px;
        height: 250px;
    }
}